<template>
  <div
    class="content"
    v-if="helpDialogRole"
  >
    <!-- 帮助中心常见问题 -->
    <div
      class="common_issue"
      v-if="commonissue"
    >
      <div class="main">
        <!-- ---------------上面 帮助中心 联系我们 退出按钮 -->
        <div class="top">
          <span class="help">{{ $t(`message.Help.Title`) }}</span>
          <div class="un">
            <a
              class="call"
              href="http://www.howtonpd.com/?page_id=25"
              target="_blank"
            >{{ $t(`message.Help.Contact`) }} </a>
            <div class="unimg"><img
                @click="un"
                class="unimg-un"
                style="cursor: pointer"
                src="../assets/image/close.png"
                alt=""
              /></div>
          </div>
        </div>
        <!-- ---------------------------下面主体部分 -->
        <div class="body">
          <div class="left">
            <!-- -------------------右上角两个按钮点击 -->
            <div class="tag">
              <div class="Title">
                <div
                  :class="isOperation == false ? 'showbtn' : 'unbtn'"
                  @click="isShowQuestion"
                ><span>{{ $t(`message.HelpCenters.CommonProblem`) }}</span></div>
                <div
                  :class="isOperation == false ? 'unbtn' : 'showbtn'"
                  @click="isShowOperation"
                ><span>{{ $t(`message.HelpCenters.OperationalGuideline`) }}</span></div>
              </div>
              <!-- 常见问题 / 百分位线 -->
              <div class="issue_button">
                <button
                  :class="isButtonColor1[0] ? 'btnss' : 'btns'"
                  @click="getcurrentHelp2"
                >
                  <span class="text">{{ $t(`message.Help.Question`) }}</span>
                </button>

                <button
                  :class="isButtonColor1[1] ? 'btnss' : 'btns'"
                  @click="getcurrentHelp3"
                >
                  <span class="text">{{ $t(`message.Help.Lines`) }}</span>
                </button>
              </div>
            </div>
            <!-- ------------蓝色点击按钮，显示我要反馈页面------- -->
            <div class="feedbackbox">
              <div
                class="feedback"
                style="cursor: pointer"
                @click="getifshow"
              >
                <img
                  class="img"
                  src="https://newstore.vynior.com/Slice.png"
                  alt=""
                />
                <span class="Wantfeedback">{{ $t(`message.Help.BackSee`) }}</span>
              </div>
            </div>
          </div>
          <!-- ------------------点击两个按钮出现对应的页面- -->
          <div
            class="right"
            v-show="isUser&&this.$store.state.lang == 'cn'"
          >
            <!-- -----------------点击按钮跳转---对应的页面- -->
            <div class="tagpage">
              <div
                class="right_content"
                v-show="currentHelp2"
              >
                <p>
                  百分位线数据从小到大排序,累计百分位 <br />
                  <br />
                  P80:第80%分位数,表示超过了80%的产品的喜好度平均值<br />
                  P60:第60%分位数,表示超过了60%的产品的喜好度平均值<br />
                  P40:第40%分位数,表示超过了40%的产品的喜好度平均值<br />
                  P20:第20%分位数,表示超过了20%的产品的喜好度平均值<br />
                  <br />
                  Note:所有分位线的结果均基于数据库的产品得出。
                </p>
              </div>

              <!----------------------- 常见问题 -->
              <div
                class="right_contents"
                v-show="currentHelp3"
              >
                <!-- 下拉框的盒子 -->
                <el-collapse
                  accordion
                  class="down-box"
                >
                  <el-collapse-item
                    title="Q1. 为什么选完项目和产品后，页面没有变化？"
                    class="down-box-one"
                  >
                    <div class="text2">
                      请检查您的网络是否良好，若网络正常，请检查是否有开始字眼相关的按钮没有点击。若依旧无反应或反应时间过长，请刷新或重启浏览器，您也可以点击<a
                        style="text-decoration: none"
                        href="http://www.howtonpd.com/?page_id=25"
                        target="_blank"
                      ><span style="color: #1d5ca6">【联系我们】</span></a>联系相关人员反馈该问题。
                    </div>
                    <img
                      style="width: 962px; height: 496px"
                      src="https://newstore.vynior.com/%E5%8D%95%E5%93%81%E5%88%86%E6%9E%90-%E7%A9%BA%E7%8A%B6%E6%80%81%202%402x.png"
                      alt=""
                    />
                  </el-collapse-item>
                  <el-collapse-item
                    title="Q2. 为什么对比分析页面有时会发生差值相同但是条形长度不同的情况？"
                    class="down-box-two"
                  >
                    <div class="text2">{{ $t(`message.Help.Question2`) }}</div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </div>
           <div
            class="right"
            v-show="isUser&&this.$store.state.lang == 'en'"
          >
            <!-- -----------------点击按钮跳转---对应的页面- -->
            <div class="tagpage">
              <div
                class="right_content"
                v-show="currentHelp2"
              >
                <p>
                 Percentile line data is sorted from smallest to largest, accumulating percentiles <br />
                  <br />
                  P80: Exceeding 80% of the products in the database.<br />
                  P60: Exceeding 60% of the products in the database.<br />
                  P40: Exceeding 40% of the products in the database.<br />
                  P20: Exceeding 20% of the products in the database.<br />
                  <br />
                 Note: All results are derived based on the data of the product
                </p>
              </div>

              <!----------------------- 常见问题 -->
              <div
                class="right_contents"
                v-show="currentHelp3"
              >
                <!-- 下拉框的盒子 -->
                <el-collapse
                  accordion
                  class="down-box"
                >
                  <el-collapse-item
                    title="Q1. Why is there no change in the page after selecting the project and product?
"
                    class="down-box-one"
                  >
                    <div class="text2">
                      Please check if your network is stable. If the network is fine, please check if you have clicked on any buttons related to the "start" action. If there is still no response or if the response time is too long, please try refreshing or restarting your browser. Alternatively, you can click on<a
                        style="text-decoration: none"
                        href="http://www.howtonpd.com/?page_id=25"
                        target="_blank"
                      ><span style="color: #1d5ca6">【Contact Us】</span></a>to reach out to the relevant personnel for feedback on this issue.。
                    </div>
                    <img
                      style="width: 962px; height: 496px"
                      src="https://newstore.vynior.com/%E5%8D%95%E5%93%81%E5%88%86%E6%9E%90-%E7%A9%BA%E7%8A%B6%E6%80%81%202%402x.png"
                      alt=""
                    />
                  </el-collapse-item>
                  <el-collapse-item
                    title="Q2. Why does the comparative analysis page sometimes show the same difference value but with different bar lengths?"
                    class="down-box-two"
                  >
                    <div class="text2">{{ $t(`message.Help.Question2`) }}</div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </div>
          <!-------------------------- 点击反馈出现的表单页面 -->
          <div
            class="right"
            v-show="this.ifshow == true"
            v-if="this.allshow == false"
          >
            <div class="right-box-content">
              <div class="rigth-box-top">
                <div class="right-top-left">
                  <div class="text1">{{ $t(`message.Help.Name`) }}</div>
                  <input
                    class="input1"
                    type="text"
                    name="name"
                    id="name"
                    v-model="formLabelAlign.name"
                    :placeholder="$t(`message.Help.Names`)"
                  />
                </div>
                <div class="right-top-right">
                  <div class="right-top-left">
                    <div class="text1">{{ $t(`message.Help.Company`) }}</div>
                    <input
                      class="input1"
                      type="text"
                      name="company"
                      id="company"
                      v-model="formLabelAlign.title"
                      :placeholder="$t(`message.Help.Companys`)"
                    />
                  </div>
                </div>
              </div>
              <div class="right-box-btm">
                <span class="img">*</span>
                <div class="text2">{{ $t(`message.Help.YourEmail`) }}</div>
                <input
                  class="input2"
                  type="text"
                  name="phone"
                  id="phone"
                  @blur="emailInfo()"
                  v-model="formLabelAlign.email"
                  :placeholder="$t(`message.Help.Email`)"
                />
                <div class="text3">{{ $t(`message.Help.Questions`) }}</div>
                <quill-editor
                  ref="myLQuillEditor"
                  v-model="formLabelAlign.htmldata"
                  :options="editorOption"
                  class="editor"
                > </quill-editor>
                <button
                  class="helpbtn"
                  @click="handleLogin"
                  style="cursor: pointer"
                >
                  <span>{{ $t(`message.Help.Send`) }}</span>
                </button>
              </div>
            </div>
          </div>
          <!----------------------------- 成功的页面 -->
          <div
            class="right"
            v-if="this.isSuccess == true"
          >
            <div class="mainbox">
              <img
                src="https://newstore.vynior.com/Slice%2020.png"
                alt=""
              />
              <span class="success">发送成功!</span>
              <div class="successtext">将在48小时内回复邮件,请您注意查收</div>
              <div class="btn">
                <div
                  class="new"
                  style="cursor: pointer"
                  @click="successOut"
                >退出反馈</div>
                <div class="i"></div>
                <div
                  class="un"
                  style="cursor: pointer"
                  @click="successKeep"
                >继续反馈</div>
              </div>
            </div>
          </div>
          <!----------------------------- 等待页面 -->
          <div
            class="right"
            v-if="this.isAwait"
          >
            <div class="mainbox">
              <img
                src="https://newstore.vynior.com/Slice%2019.png"
                alt=""
              />
              <span class="sucess">发送中...</span>
            </div>
          </div>
          <!---------------------------- 失败页面 -->
          <div
            class="right"
            v-if="this.isReject"
          >
            <div class="mainbox">
              <img
                src="https://newstore.vynior.com/Slice%2021.png"
                alt=""
              />
              <span class="success">发送失败!</span>
              <div class="rejecttext">遇到未知错误,您可以尝试重新发送或退出反馈</div>
              <div class="btn">
                <div
                  class="un"
                  @click="rejectOut"
                  style="cursor: pointer"
                >退出反馈</div>
                <div class="i"></div>
                <div
                  class="new"
                  @click="rejectKeep"
                  style="cursor: pointer"
                >重新发送</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 帮助中心 操作指引 -->
    <div
      class="operation_guide"
      v-if="operationguide"
    >
      <div class="main">
        <!-- ----------头部 操作指引联系我们 和退出按钮 -->
        <div class="top">
          <span class="help">{{ $t(`message.Help.Title`) }}</span>
          <div class="un">
            <a
              class="call"
              href="http://www.howtonpd.com/?page_id=25"
              target="_blank"
            >{{ $t(`message.Help.Contact`) }} </a>
            <img
              @click="un"
              style="cursor: pointer"
              src="../assets/image/close.png"
              alt=""
            />
          </div>
        </div>
        <!-- 主体中间盒子  中文显示 -->
        <div class="body" v-if="$store.state.lang =='cn'" >
          <!-- 左边盒子 -->
          <div class="left">
            <!-- -----------9个按钮 -->

            <div class="mainbtns">
              <div class="Title">
                <div
                  :class="isOperation == false ? 'showbtn' : 'unbtn'"
                  @click="isShowQuestion"
                ><span>{{ $t(`message.HelpCenters.CommonProblem`) }}</span></div>
                <div
                  :class="isOperation == false ? 'unbtn' : 'showbtn'"
                  @click="isShowOperation"
                ><span>{{ $t(`message.HelpCenters.OperationalGuideline`) }}</span></div>
              </div>
              <button
                :class="isButtonColor[0] ? 'btnss' : 'btns'"
                @click="link1"
              ><span class="text">{{ $t(`message.Header.two`) }}</span></button>
              <button
                :class="isButtonColor[1] ? 'btnss' : 'btns'"
                @click="link2"
              ><span class="text">{{ $t(`message.Header.single`) }}</span></button>
              <button
                :class="isButtonColor[2] ? 'btnss' : 'btns'"
                @click="link3"
              ><span class="text">{{ $t(`message.Header.compare`) }}</span></button>
              <button
                :class="isButtonColor[3] ? 'btnss' : 'btns'"
                @click="link4"
              ><span class="text">{{ $t(`message.Header.five`) }}</span></button>
              <button
                :class="isButtonColor[4] ? 'btnss' : 'btns'"
                @click="link5"
              ><span class="text">{{ $t(`message.Header.profile`) }}</span></button>
              <button
                :class="isButtonColor[5] ? 'btnss' : 'btns'"
                @click="link6"
              ><span class="text">{{ $t(`message.Header.classification`) }}</span></button>
              <button
                :class="isButtonColor[6] ? 'btnss' : 'btns'"
                @click="link7"
              ><span class="text">{{ $t(`message.Header.Feature`) }}</span></button>
              <button
                :class="isButtonColor[7] ? 'btnss' : 'btns'"
                @click="link8"
              ><span class="text">{{ $t(`message.Header.significance`) }}</span></button>
              <button
                :class="isButtonColor[8] ? 'btnss' : 'btns'"
                @click="link9"
              ><span class="text">{{ $t(`message.HelpCenters.Allvideos`) }}</span></button>
            </div>
            <!-- -----左下方盒子---- -->
            <div class="left-bottom">
              <!-- ----------操作信息- -->

              <!-- ---------操作盒子---- -->
              <div class="bottom-box">
                <div class="box-top">
                  <div class="top-left">
                    <div class="top-left-text-un">
                      <span>操作视频与选择的指引相呼应<br />
                        点击更多跳转全部视频</span>
                    </div>
                    <div class="top-left-text">
                      操作视频
                      <img
                        class="img-box"
                        src="https://newstore.vynior.com/Subtract.png"
                        alt=""
                      />
                    </div>

                    <div class="top-left-span">视频共5分钟</div>
                  </div>
                  <div
                    class="top-right"
                    style="cursor: pointer"
                    @click="link9"
                  >更多</div>
                </div>
                <div
                  class="box-main"
                  @click="videoPlay(9)"
                  style="cursor: pointer"
                >
                  <video
                    ref="myVideo"
                    class="img1-video"
                    @play="videoPlay(9)"
                    poster="https://newstore.vynior.com/hm1.png"
                  >
                    <source
                      src="http://www.ht-fast.com/home_video/%E5%AE%A3%E4%BC%A0%E7%89%87%20%E5%85%A8%E5%93%81%E7%B1%BB%20%E5%AD%97%E5%B9%95%E5%B7%B2%E4%BF%AE%E6%94%B91676532931352.mp4"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 左边盒子 新建项目  -->
          <div
            class="right"
            v-if="ifShow[0]"
          >
            <div class="right-top">
              <div class="right-top-img"></div>
              <div class="right-top-text">新建项目</div>
              <div class="right-top-span">（操作步骤如下）</div>
            </div>
            <div class="right-text1">进入新建项目页面，必须上传正确的文件才能进行之后的操作</div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033958.png"
              alt=""
            />
            <div class="right-title1">·下载模板</div>
            <div class="right-text2">
              <p>· 若您为初次使用，请先【下载模版】。</p>
              <p>· 下载的模板需填写项目编号&产品信息&感官属性数据，模板中含有2个工作表，分别是【正式模板】即信息填写处以</p>
              <p>及【模板样例】供参考。</p>
              <sapn style="color: #eb5e12">注意:</sapn>为了确保模板可以顺利导入，在【正式模板】中输入信息前，请先参考【模板样例】并阅读注意事项。
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2034061%402x.png"
              alt=""
            />
            <div class="right-title1">·上传文件</div>
            <div class="right-text3">
           <p>点击【上传文件】，可以将文件内填写的数据和内容快捷统一导入到页面中。上传文件成功后，页面展示共由两个部分</p>
             <p>组成，如下：</p>
               <p> ① 新建项目 </p>
               <p>· 项目编号位于左上方，可以通过点击图标进行修改。</p>
                <p> 注意：项目号不可与网站中已有项目的编号重复。</p>
                 <p>② 产品信息 </p>
                  <p>· 产品信息表格包含了产品的相关信息及感官数据，其中产品名称为必填项。</p>
                   <p>· 信息填写的越完整越能进一步完善之后的分析结果。</p>
                    <p>· 根据表格右上方产品品类的不同，此处产品信息也会有相应的变化。</p>
                     <sapn style="color: #eb5e12">注意:</sapn>一个项目内的产品必须为同一品类。
            </div>
            <br />
            <br />
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033951%402x.png"
              alt=""
            />
            <div class="right-title1">·完成新建项目</div>
            <div class="right-text12">
              <p>点击【保存】按钮，保存所编辑的信息，再点击【完成】按钮，则该项目建立完成，页面将跳转至已有项目。此时页面</p>
              <p>内的内容会进行一次校验，若项目编号重复或产品名称重复都无法进行保存，需要进行修改。</p>
            </div>
            <img
              class="loding-img2"
             src="https://newstore.vynior.com/Group%2033992%402x.png"
              alt=""
            />
             <div class="right-title1">·单品分析</div>
            <div class="right-text4">在已有项目页面中，点击预测可跳转至产品的单品分析界面。</div>
            <img
              class="loding-img1"
               src="https://newstore.vynior.com/Group%2033931%402x.png"
              alt=""
            />
          </div>
          <!--------左边盒子  单品分析------ -->
          <div
            class="right"
            v-if="ifShow[1]"
          >
            <div class="right-top">
              <div class="right-top-img"></div>
              <div class="right-top-text">单品分析</div>
              <div class="right-top-span">（操作步骤如下）</div>
            </div>
            <div class="right-text5">
              <p>选择一个项目内的一个产品进行预测分析与数据可视化展示，同时还可选择对比产品进行对比观察。（<span style="color: #eb5e12">注意：</span>对比产品</p>
              <p>至多可选择4个）</p>
            </div>
            <div class="right-title1">·开始预测</div>

            <div class="right-text6">
              如何进行预测？<br />
              <p>① 在开始预测前，必须先选择一个已有项目并选择该项目内的单个产品（目标人群为非必选项）。</p>
              <p> ② 选择完成后点击【开始预测】按钮，预测时长大约15s。</p>
              <span style="color: #eb5e12">注意：</span>点击开始预测后请等待算法运行完毕再进行下一次预测，若响应超时可通过刷新页面重新预测。
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033939.png"
              alt=""
            />
            <div class="right-title1">·感官数据</div>
            <div class="right-text6">
             <p>· 位于最左侧一栏，通过数据可视化图表展示。图表包含重要度图标、属性数 据名称以及数据、数值图、名称标签和标</p>
             <p>签数值。</p>
             <p> · 蓝色色条为产品的感官数值图。选择了对比产品后，对比产品的感官数据也会在此显示。</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033972%402x.png"
              alt=""
            />
            <div class="right-title1">·消费者指标预测</div>
            <div class="right-text7">消费者指标预测位于中间一栏，其结果通过感官数据预测得出。主要分为整体喜好度、综合性指标和特征指标。</div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033965.png"
              alt=""
            />
            <div class="right-title1">（1）整体喜好度</div>
            <div class="right-text7">产品的整体喜好度以图表形式展示，图表类型可进行切换。</div>
            <img
              class="loding-img"
               src="https://newstore.vynior.com/Group%2033969%402x.png"
              alt=""
            />
            <div class="right-title1">（2）综合性指标&特征指标</div>
            <div class="right-text7">每个指标包含指标名称、指标喜好度以及该指标数值的预测精准度。</div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2034062%402x.png"
              alt=""
            />
            <div class="right-title1">·选择对比产品</div>
            <div class="right-text8">
            <p>· 位于最右侧一栏，包含推荐优化方案和历史产品选择。 用户可对其进行选择，与分析的产品进行对比。</p>
            <p>· 3个推荐产品为系统根据模型生成的推荐优化方案。</p>
            <p>· 历史产品为已有项目中，同一品类且已分析的产品。</p>
              <span style="color: #eb5e12">注意：</span>对比产品的选择上限为4个。
            </div>
             <div class="right-title1">·感官数据</div>
              <div class="right-text7">
                点击【对比分析】按钮，页面将跳转至对比分析并保留选择项目和选择产品。
            </div>
              <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033924%402x.png"
              alt=""
            />
            <img
              class="loding-img"
             src="https://newstore.vynior.com/Group%20339244%402x.png"
              alt=""
            />
          </div>

          <!-- ----------左边盒子   对比分析 -->
          <div
            class="right"
            v-if="ifShow[2]"
          >
            <div class="right-top">
              <div class="right-top-img"></div>
              <div class="right-top-text">对比分析</div>
              <div class="right-top-span">（操作步骤如下）</div>
            </div>
            <div class="right-text7">
              <p>针对多个产品的感官属性分析比较页面，可自由选择同一品类，不同项目中的产品进行数据可视化展示观察。（<span style="color: #eb5e12">注意：</span></p>
              <p>对比产品至多可选择10个）</p>
            </div>
            <img
              class="loding-img1"
                src="https://newstore.vynior.com/Group%2033995%402x.png"
              alt=""
            />
             <div class="right-title1">·感官属性数据</div>
            <div class="right-text9">
              <p>· 主要由重要度、属性名称、属性值、产品名称以及对应图表组成。</p>
               <p>· 属性名称会根据品类的不同做出相应的改变。</p>
                <p> · 属性数据数值范围为0-15，保留到小数点后1位。</p>
                 <p> · 深蓝色的色条为属性的数值图。</p>

            </div>
           <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033979.png"
              alt=""
            />
            <div class="right-title1">·对比分析结果</div>
            <div class="right-text9">
             <p>· 由对比产品名称和正负差值图表组成。</p>
             <p> · 图表中绿色色条代表负值，红色代表正值，色条下会显示该产品与选择产品间的感官差值，数值保留到小数点后1位。</p>
             <p> · 若想查看选择的对比产品的项目号和产品全称，可将鼠标悬浮在对比分析结果下某个产品名称上，即可在浮窗中得到相</p>
             <p>应信息。</p>
            </div>
            <img
              class="loding-img"
               src="https://newstore.vynior.com/Group%2033980%402x.png"
              alt=""
            />
            <div class="right-title1">·单品分析</div>
            <div class="right-text7">点击【单品分析】按钮，将跳转到单品分析页面并保留选择项目和选择产品。</div>
            <img
              class="loding-img2"
             src="https://newstore.vynior.com/Group%2033974%402x.png"
              alt=""
            />
          </div>

          <!-- -------左边盒子  历史数据比较 --- -->
          <div
            class="right"
            v-if="ifShow[3]"
          >
            <div class="right-top">
              <div class="right-top-img"></div>
              <div class="right-top-text">历史数据比较</div>
            </div>

            <div class="right-text1">该板块可将所有历史项目中，同一品类多个产品的感官属性与喜好度预测值进行比较，进行可视化展现。</div>
             <div class="right-title1"> 操作步骤:</div>
            <div class="right-text6">
            <p>① 首先点击【选择品类】，确认项目品类。</p>
             <p>② 再点击【选择对比产品】，可选择单个或多个需要对比的产品进行感官属性或喜好度预测值数据的对比分析。</p>
              <p><span style="color: #eb5e12">注意：</span>柱状图对比产品选择至多30个，雷达图对比产品选择至多8个。</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033943.png"
              alt=""
            />
            <div class="right-title1">·感官属性比较&喜好度预测值比较</div>
            <div class="right-text9">
              选择对比产品完成后，页面默认展示感官属性比较的可视化数据柱状图。 【喜好度预测值比较】页面需要点击【开始预测】进行分析才会展示结果。
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033947.png"
              alt=""
            />
            <div class="right-text1">感官属性比较和喜好度预测值比较页面包含柱状图和雷达图两种样式。</div>
            <div class="right-title2">（1）柱形图</div>
            <div class="right-text6">
              <p>· 柱状图页面包含柱状图表与数据列表两个版块。</p>
               <p>· 柱状图表主要展现对比产品的项目号与名称、标题（展示选中的感官属性/喜好度属性名称）、柱状图以及刻度值（感</p>
                <p>官属性：0-15；喜好度预测值：0-5）。</p>
                 <p>· 数据列表则主要展现感官属性/喜好度指标名称与对应的数据。</p>
                  <p><span style="color: #eb5e12">注意：</span>感官属性数据保留到小数点后一位；喜好度预测值数据保留到小数点后两位。</p>
                   <p></p>
            </div>
            <img
              class="loding-img3"
              src="https://newstore.vynior.com/Group%2033987.png"
              alt=""
            />
            <div class="right-text6">
              操作步骤如下： <br />鼠标放置数据列表版块可进行上下滚动预览（选择产品＞15个时，可进行左右滑动查看）。点击数据列表中的感官属性进行查看。
              （注意：喜好度预测值需要点击【开始预测】经过算法分析后才会有数据图表展示。否则，页面将无数据显示。）
            </div>
            <img
              class="loding-img"
             src="https://newstore.vynior.com/Group%2033988%402x.png"
              alt=""
            />
            <div class="right-title1">·如何快速查看完整的项目编号&产品名称与属性名称？</div>
            <div class="right-text1">将鼠标悬浮至项目编号&产品名称或属性名称，保持1s以上，将会有详细名称标签显示。</div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/%E6%88%AA%E5%B1%8F2023-03-01%E4%B8%8B%E5%8D%885.49%201.png"
              alt=""
            />
            <div class="right-title1">·百分位线</div>
            <div class="right-text5">
              仅喜好度预测值下的柱状图有此功能，点击【百分位线】按钮，控制百分位线的显示与隐藏；鼠标悬浮停留于【百分位线】标题文字1s以上，会显示百分位线说明。
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/%E6%88%AA%E5%B1%8F2023-03-01%E4%B8%8B%E5%8D%885.49%202.png"
              alt=""
            />
            <div class="right-title1">（2）雷达图</div>
            <div class="right-text10">
              点击【雷达图】切换至雷达图样式的属性比较页面，页面包含雷达图与数据列表两个版块。其中，左侧数据列表主要展示感官属性/喜好度属性名称（可供用户自定义选择想要查看的属性）、产品的项目编号&产品名称与对应的数据（感官属性数据保留小数点后一位；喜好度预测值数据保留小数点后两位。）
              右侧雷达图主要展示属性名称&径向轴（每个径向轴对应一个属性名称：属性名称&径向轴数量与选择的属性数量一致）、刻度数字（可滑动开关显示或隐藏刻度数字）、雷达链、数据点（鼠标悬浮数据点将展现该点对应的产品名称&属性数据）以及图例。
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033990.png"
              alt=""
            />
            <div class="right-text9">
              操作步骤如下：<br />属性列表默认展示10项属性，可以点击【选择】展开属性菜单下拉框，上下滚动预览属性，自行选择需要查看的属性，点击下拉菜单以外的任意位置下拉框将自动收起。
              选择属性个数至少3项，至多40项，当选择属性少于三项时将自动恢复至默认10项属性。
              （<span style="color: #eb5e12">注意：</span>喜好度预测值需要点击【开始预测】经过算法分析后才会有数据图表展示。否则，页面将无数据显示。）
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033948.png"
              alt=""
            />
            <div class="right-title1">·重置</div>
            <div class="right-text7">点击【重置】属性，所选属性恢复至默认的10项。</div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033949.png"
              alt=""
            />
            <div class="right-title1">·如何隐藏数据列表？</div>
            <div class="right-text7">点击【左箭头】按钮，数据列表收起，将单独展示雷达图，点击【展开数据列表】，数据列表与雷达图复原。</div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033950.png"
              alt=""
            />
            <div class="right-title1">·如何快速查看雷达图上产品对应的的属性数据？</div>
            <div class="right-text7">将鼠标悬浮在雷达链上的数据点，就会显示该雷达链对应的产品名称与属性数值。</div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/%E6%88%AA%E5%B1%8F2023-03-01%E4%B8%8B%E5%8D%885.56%201.png"
              alt=""
            />
          </div>

          <!-- 左边盒子  产品轮廓 -->
          <div
            class="right"
            v-if="ifShow[4]"
          >
            <div class="right-top">
              <div class="right-top-img"></div>
              <div class="right-top-text">产品轮廓</div>
            </div>
            <div class="right-text9">
             <p>产品轮廓图：展示的是当前主体数据库中的每个感官属性分值的波动范围，其中MAX表示最大值，MIN表示最小值。通</p>
             <p>过此图可以获知选择的产品在品类当中的感官特征分布。</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/%E6%88%AA%E5%B1%8F2023-03-01%E4%B8%8B%E5%8D%882.52%202.png"
              alt=""
            />
            <div class="right-text6">
              <span style="font-weight: 500">操作步骤：</span>
              <p>① 首先点击【选择品类】，确认项目品类。</p>
              <p> ② 再点击【选择分析产品】，从下拉菜单中选择单个或多个产品（<span style="color: #eb5e12">注意：</span>最多选择10个产品）。</p>
              <p> ③ 点击【开始分析】进行算法分析，等待算法分析完成，将显示所选产品折线数据图表。</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033932.png"
              alt=""
            />
            <div class="right-title1">·高低峰值显示</div>
            <div
              class="right-text2"
              style="height: 27px"
            >滑动按钮可控制高低峰值范围的隐藏和显示（默认为显示状态）。</div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033933%402x.png"
              alt=""
            />
          </div>

          <!-- 左边盒子  产品分类-->
          <div
            class="right"
            v-if="ifShow[5]"
          >
            <div class="right-top">
              <div class="right-top-img"></div>
              <div class="right-top-text">产品分类</div>
            </div>
            <div class="right-text6">
            <p>根据分类选项将产品分为不同类别，并将产品标签用不同的颜色表示。（针对防晒品类）SPF数值分为四种不同SPF分</p>
            <p>段，分别是SPF＜30、30≤SPF＜50、SPF≥50和暂无。防晒分类分为物理防晒、化学防晒和物化结合。通过此图可以</p>
            <p>了解选择分析的产品与哪些产品比较相似。</p>
            <p><span style="color: #eb5e12">注意：</span>产品标签SSXXX（例如：SS888）表示后台数据库中的产品编号。</p>
            </div>
            <div class="right-text6">
              操作步骤：
            <p>① 首先点击【选择品类】，确认完项目品类后将会现初始状态层次聚类图（此时图中均为数据库内的产品，产品编号默认显示为黑色）。</p>
            <p>② 再点击【选择分析产品】，从下拉菜单中选择单个或多个产品。</p>
            <p>③ 点击【开始分析】进行算法分析，等待算法分析完成，层次聚类图中会出现选择的产品的名称，并显示为红色。</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033936.png"
              alt=""
            />
            <div class="right-title1">·产品标签</div>
            <div class="right-text9">
           该按钮的作用是控制数据库产品号在层次聚类图中的显示与隐藏。
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2034063.png"
              alt=""
            />
            <div class="right-title1">·分类选项--SPF数值&防晒分类</div>
            <div class="right-text6">
            <p>· 点击【SPF数值】的圆形单选框，会看到SPF数值分类图例，用户可以根据该图例快速知晓层次聚类图中对应颜色的产品的分类状况</p>
            <p> · 如：SPF＜30的产品标记为黄色，则右侧层次聚类图中SPF＜30的产品编号也会显示为黄色）； 防晒分类同理。</p>
            <p>· 再次点击单选框，可取消选择。</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033983.png"
              alt=""
            />
          </div>

          <!-- 左边盒子  特征分析 -->
          <div
            class="right"
            v-if="ifShow[6]"
          >
            <div class="right-top">
              <div class="right-top-img"></div>
              <div class="right-top-text">特征分析</div>
            </div>
            <div class="right-text9">
            <p>通过此图可以获知选择的产品在Sensory Map中的位置。</p>
             <p><span style="color: #eb5e12">图例说明：</span>图中蓝色星形为用户选择进行分析的产品，三角形为数据库内产品，粉色圆形为该品类的感官属性。</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/%E6%88%AA%E5%B1%8F2023-03-01%E4%B8%8B%E5%8D%883.43%201.png"
              alt=""
            />
            <div class="right-text6">
              <span><span style="font-weight: 500">操作步骤：</span></span>
            <p>①首先点击【选择品类】，确认项目品类后，再点击【选择分析产品】，从下拉菜单中选择单个或多个产品，点击【开</p>
             <p>始分析】进行算法分析，等待算法分析完成，所选产品名称以及图例将显示在感官地图中。</p>
              <p>②分析完成的图表可进行坐标选择以及“主要成分分析”和“因素旋转分析”两个选择。</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033938.png"
              alt=""
            />
            <div class="right-title1">·坐标选择</div>
            <div class="right-text12">
              <p>点击【坐标选择】，可上下滚动预览选择坐标（共有15种坐标组合选择，默认坐标为F1&F2）。Sensory Map会随着选</p>
              <p>择的坐标而变化。</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033985%402x.png"
              alt=""
            />
            <div class="right-title1">·主要成分分析&因素旋转分析</div>
            <div class="right-text6">
            <p>· 主要成分分析与因素旋转分析中均包括：算法分类、SPF数值与防晒分类三个选项。</p>
            <p> · 当选择【算法分类】的【默认】选项时，图中三角形（数据库内产品）显示为灰色，无算法状态分类。此时，用户可以</p>
            <p>点击【默认】展开下拉菜单，选择分类选项，如：选择6类，数据库内的产品将会一局感官数据被分成6类，散点图中的</p>
            <p>三角形也将以6种颜色呈现；其他分类同理。</p>
            <p> · 当选择【SPF数值】时，图中三角形的颜色会根据不同SPF值范围而改变，防晒分类同理。</p>
            </div>
            <img
              class="loding-img"
             src="https://newstore.vynior.com/Group%2033984%402x.png"
              alt=""
            />
          </div>

          <!-- 左边盒子 重要度分析 -->
          <div
            class="right"
            v-if="ifShow[7]"
          >
            <div class="right-top">
              <div class="right-top-img"></div>
              <div class="right-top-text">重要度分析</div>
            </div>

            <div class="right-text8">
             <p>通过这个板块可以获知品类属性之间的关联度。共有三类坐标可选，分别为“感官属性”、“感官属性/消费者指标”、“消</p>
              <p>费者指标”。</p>
               <p><span style="color: #eb5e12">注意：</span>“感官属性”和“消费者指标”分析会显示热力图，“感官属性/消费者指标”显示热力图与网络图两种图表。</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033986.png"
              alt=""
            />
            <div class="right-title1">·热力图解析：</div>
            <div class="right-text9">
              <p>小方块的颜色越深，则表示横纵两个属性之间的关联度越大。颜色越浅，纵横两个属性的关联度越小。鼠标悬浮于热力</p>
              <p>图方块上，停留1s以上，将展示该方块表示的具体数值，正值表示正关联，负值表示负关联。</p>
            </div>
            <img
              class="loding-img3"
               src="https://newstore.vynior.com/Group%2033996%402x.png"
              alt=""
            />
            <div class="right-title1">·网络图解析：</div>
            <div class="right-text9">
           <p>显示了对消费者指标影响最大的5个感官属性（消费者指标可以切换）。左侧为消费者指标，右侧为感官属性，两两以直</p>
           <p>线相连，直线的颜色代表了正负相关性，直线上显示的值为关联度，其绝对值越大，代表两者的关联性越强。</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/%E6%88%AA%E5%B1%8F2023-03-01%E4%B8%8B%E5%8D%883.27.png"
              alt=""
            />
              <div class="right-title1">操作步骤：</div>
            <div class="right-text6">
           <p>① 首先点击【选择品类】，确认项目品类。</p>
            <p> ② 再点击【选择坐标】，从下拉菜单中选择“感官属性”、“感官属性/消费者指标”、“消费者指标”中的任意一项。</p>
             <p> ③ 点击【开始分析】进行算法分析，等待算法分析完成，弹窗消失，将显示所选坐标的热力图。</p>
              <p><span style="color: #eb5e12">注意：</span>感官属性/消费者指标的分析结果会额外有坐标选择和图表类型选择。</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033937.png"
              alt=""
            />
            <div class="right-title1">·坐标选择&重置（仅限感官属性/消费者指标）</div>
            <div class="right-text7">
              <p>坐标选择默认“整体喜好度”选项。点击选择框，选择需要查看的属性，此时网络图也会随之改变。（<span style="color: #eb5e12">注意：</span>只有切换成</p>
               <p>网络图时才可以进行坐标选择）</p>
            </div>
            <img
              class="loding-img"
                src="https://newstore.vynior.com/Group%2033946%402x.png"
             
              alt=""
            />
          </div>

          <!-- 左边盒子  全部视频 -->
          <div
            class="rigth-videos"
            v-if="ifShow[8]"
          >
            <div class="videos-title">{{ $t(`message.HelpCenters.Allvideos`) }}</div>
            <div class="videos-box">
              <div class="videos">
                <div
                  class="video"
                  @click="videoPlay(1)"
                  style="cursor: pointer"
                >
                  <video
                    ref="myVideo"
                    style="cursor: pointer"
                    class="img1-video"
                    @play="videoPlay(1)"
                    poster="https://newstore.vynior.com/Rectangle%201533%281%29.png"
                  >
                    <source
                      :src="videourl"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
                <div class="video-bottom">新建项目</div>
              </div>
              <div class="videos">
                <div
                  class="video"
                  @click="videoPlay(2)"
                  style="cursor: pointer"
                >
                  <video
                    ref="myVideo"
                    class="img1-video"
                    @play="videoPlay(2)"
                    poster="https://newstore.vynior.com/Rectangle%201533.png"
                  >
                    <!--   controls  控件是否展示 -->
                    <source
                      :src="videourl"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
                <div class="video-bottom">单品分析</div>
              </div>
              <div class="videos">
                <div
                  class="video"
                  @click="videoPlay(3)"
                  style="cursor: pointer"
                >
                  <video
                    ref="myVideo"
                    class="img1-video"
                    @play="videoPlay(3)"
                    poster="https://newstore.vynior.com/Rectangle%201708.png"
                  >
                    <!--   controls  控件是否展示 -->
                    <source
                      :src="videourl"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
                <div class="video-bottom">对比分析</div>
              </div>
              <div class="videos">
                <div
                  class="video"
                  @click="videoPlay(4)"
                  style="cursor: pointer"
                >
                  <video
                    ref="myVideo"
                    class="img1-video"
                    @play="videoPlay(4)"
                    poster="https://newstore.vynior.com/Group%2034069.png"
                  >
                    <source
                      :src="videourl"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
                <div class="video-bottom">产品轮廓</div>
              </div>
              <div class="videos">
                <div
                  class="video"
                  @click="videoPlay(5)"
                  style="cursor: pointer"
                >
                  <!-- <img
                  class="img1-video"
                  src=""
                  alt=""
                > -->

                  <video
                    ref="myVideo"
                    class="img1-video"
                    @play="videoPlay(5)"
                    poster="https://newstore.vynior.com/Rectangle%201533%282%29.png"
                  >
                    <source
                      :src="videourl"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
                <div class="video-bottom">产品分类</div>
              </div>
              <div class="videos">
                <div
                  class="video"
                  @click="videoPlay(6)"
                  style="cursor: pointer"
                >
                  <!-- <img
                  class="img1-video"
                  src=""
                  alt=""
                > -->

                  <video
                    ref="myVideo"
                    class="img1-video"
                    @play="videoPlay(6)"
                    poster="https://newstore.vynior.com/Rectangle%201708%281%29.png"
                  >
                    <source
                      :src="videourl"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
                <div class="video-bottom">特征分析</div>
              </div>
              <div class="videos">
                <div
                  class="video"
                  @click="videoPlay(8)"
                  style="cursor: pointer"
                >
                  <video
                    ref="myVideo"
                    class="img1-video"
                    @play="videoPlay(8)"
                    poster="https://newstore.vynior.com/Rectangle%201535%281%29.png"
                  >
                    <source
                      :src="videourl"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
                <div
                  class="video-bottom"
                  style="width: 180px"
                >重要度分析</div>
              </div>
              <div class="videos">
                <div
                  class="video"
                  @click="videoPlay(8)"
                  style="cursor: pointer"
                >
                  <video
                    ref="myVideo"
                    class="img1-video"
                    @play="videoPlay(8)"
                    poster="https://newstore.vynior.com/Rectangle%201534%281%29.png"
                  >
                    <source
                      :src="videourl"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
                <div
                  class="video-bottom"
                  style="width: 180px"
                >历史数据比较</div>
              </div>
            </div>
          </div>
        </div>
          <div class="body" v-if="$store.state.lang =='en'">
          <!-- 左边盒子 -->
          <div class="left">
            <!-- -----------9个按钮 -->

            <div class="mainbtns">
              <div class="Title">
                <div
                  :class="isOperation == false ? 'showbtn' : 'unbtn'"
                  @click="isShowQuestion"
                ><span>{{ $t(`message.HelpCenters.CommonProblem`) }}</span></div>
                <div
                  :class="isOperation == false ? 'unbtn' : 'showbtn'"
                  @click="isShowOperation"
                ><span>{{ $t(`message.HelpCenters.OperationalGuideline`) }}</span></div>
              </div>
              <button
                :class="isButtonColor[0] ? 'btnss' : 'btns'"
                @click="link1"
              ><span class="text">{{ $t(`message.Header.two`) }}</span></button>
              <button
                :class="isButtonColor[1] ? 'btnss' : 'btns'"
                @click="link2"
              ><span class="text">{{ $t(`message.Header.single`) }}</span></button>
              <button
                :class="isButtonColor[2] ? 'btnss' : 'btns'"
                @click="link3"
              ><span class="text">{{ $t(`message.Header.compare`) }}</span></button>
              <button
                :class="isButtonColor[3] ? 'btnss' : 'btns'"
                @click="link4"
              ><span class="text">{{ $t(`message.Header.five`) }}</span></button>
              <button
                :class="isButtonColor[4] ? 'btnss' : 'btns'"
                @click="link5"
              ><span class="text">{{ $t(`message.Header.profile`) }}</span></button>
              <button
                :class="isButtonColor[5] ? 'btnss' : 'btns'"
                @click="link6"
              ><span class="text">{{ $t(`message.Header.classification`) }}</span></button>
              <button
                :class="isButtonColor[6] ? 'btnss' : 'btns'"
                @click="link7"
              ><span class="text">{{ $t(`message.Header.Feature`) }}</span></button>
              <button
                :class="isButtonColor[7] ? 'btnss' : 'btns'"
                @click="link8"
              ><span class="text">{{ $t(`message.Header.significance`) }}</span></button>
              <button
                :class="isButtonColor[8] ? 'btnss' : 'btns'"
                @click="link9"
              ><span class="text">{{ $t(`message.HelpCenters.Allvideos`) }}</span></button>
            </div>
            <!-- -----左下方盒子---- -->
            <div class="left-bottom">
              <!-- ----------操作信息- -->

              <!-- ---------操作盒子---- -->
              <div class="bottom-box">
                <div class="box-top">
                  <div class="top-left">
                    <div class="top-left-text-un">
                      <span> {{ $t(`message.HelpCenters.Operationvideo`) }}<br />
                         {{ $t(`message.HelpCenters.Clickvideos`) }}</span>
                    </div>
                    <div class="top-left-text">
                     {{ $t(`message.HelpCenters.Operatevideo`) }}
                      <img
                        class="img-box"
                        src="https://newstore.vynior.com/Subtract.png"
                        alt=""
                      />
                    </div>

                    <div class="top-left-span">{{ $t(`message.HelpCenters.videoisminutes`) }}</div>
                  </div>
                  <div
                    class="top-right"
                    style="cursor: pointer"
                    @click="link9"
                  > {{ $t(`message.HelpCenters.More`) }}</div>
                </div>
                <div
                  class="box-main"
                  @click="videoPlay(9)"
                  style="cursor: pointer"
                >
                  <video
                    ref="myVideo"
                    class="img1-video"
                    @play="videoPlay(9)"
                    poster="https://newstore.vynior.com/hm1.png"
                  >
                    <source
                      src="http://www.ht-fast.com/home_video/%E5%AE%A3%E4%BC%A0%E7%89%87%20%E5%85%A8%E5%93%81%E7%B1%BB%20%E5%AD%97%E5%B9%95%E5%B7%B2%E4%BF%AE%E6%94%B91676532931352.mp4"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 左边盒子 新建项目  -->
          <div
            class="right"
            v-if="ifShow[0]"
          >
            <div class="right-top">
              <div class="right-top-img"></div>
              <div class="right-top-text">Creating a New Project </div>
              <div class="right-top-span">(Steps)</div>
            </div>
            <div class="right-text1">To create a new project, the correct file must be uploaded before proceeding with any other operations.</div>
            <img
              class="loding-img"
               src="https://newstore.vynior.com/Group%2033958%402x.png"
              alt=""
            />
            <div class="right-title1">·Download Template</div>
            <div class="right-text13">
              <p>· If this is your first time using FAST, please "Download Template" first. </p>
              <p>· The downloaded template needs to be filled with Project No. & Product Information & Sensory Attribute Data. </p>
              <p>The template contains 2 sheets: the [Template] for entering information and the [Example] for reference.</p>
              <sapn style="color: #eb5e12">Note:</sapn>In order to ensure a success import of the template, please refer to the [Example] and read the notes in the 
              <p>excel carefully before entering any information on [Template].</p>
            </div>
            <img
              class="loding-img4"
              src="https://newstore.vynior.com/Groupen34061%402x.png"
              alt=""
            />
            <div class="right-title1">·Upload File</div>
            <div class="right-text14">
           <p>Click "Upload File" to import information & data into the page. After a successful upload, the page will display</p>
             <p>two parts as follows:</p>
               <p>① New Project  </p>
               <p> · The Project No. is in the upper left corner and can be modified by clicking on the icon.</p>
                <p> <sapn style="color: #eb5e12">Note:</sapn>Project No. cannot be duplicated with existing project numbers on the website.</p>
                 <p>② Product Information </p>
                  <p>· The product information table contains relevant information and sensory data about the product, where the </p>
                   <p>product name is a required field.</p>
                    <p>· The more complete the product information is filled out, the better the subsequent analysis results will be.</p>
                    <p>· Depending on the product category, there will be corresponding changes in the product information here.</p>
                     <sapn style="color: #eb5e12">Note:</sapn> All products within a project must belong to the same category.

            </div>
            <br />
            <br />
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Groupen33951%402x.png"
              alt=""
            />
            <div class="right-title1">Complete Project Creation Process</div>
            <div class="right-text8">
              <p>Click the "Save" button to save the edited information. Then click the "Finish" button to complete the project </p>
              <p>creation process, and the page will jump to the [Existing Project]. At this time, the content of the page will be</p>
              <p>validated. If the project number or product name is duplicated, it cannot be saved until modified.</p>
            </div>
            <img
              class="loding-img6"
             src="https://newstore.vynior.com/Group%2034143%402x.png"
              alt=""
            />
             <div class="right-title1">Product Analysis</div>
            <div class="right-text4">In the existing project page, click "Predict" to go to the single product analysis page for prediction.</div>
            <img
              class="loding-img1"
               src="https://newstore.vynior.com/Groupen33931%402x.png"
              alt=""
            />
          </div>
          <!--------左边盒子  单品分析------ -->
          <div  
            class="right"
            v-if="ifShow[1]"
          >
            <div class="right-top">
              <div class="right-top-img"></div>
              <div class="right-top-text1">Performance Prediction: This section is used for predicting product performance, recommending <br>optimization solutions, and single product/comparative analysis.</div>
            </div>
              <div class="right-title1">Single Product Analysis (Steps)</div>
            <div class="right-text5">
                
              <p>Select a product from the existing project for prediction and data visualization. You can also choose other </p>
              <p>products for comparison. (Note: Up to 4 products can be selected for comparison).</p>
            </div>
            <div class="right-title1">·Start forecasting</div>
            <div class="right-title1"> How to predict?</div>
            <div class="right-text6">
              <p>① Before starting the prediction, you must first select an existing project and a product from it ("Target</p>
              <p>Consumer" is optional).</p>
              <span style="color: #eb5e12">Note: </span>Please wait for the algorithm to finish running before making the next prediction. If the response times out, 
              <p>you can refresh the page and try again.</p>
            </div>
            <img
              class="loding-img"
                src="https://newstore.vynior.com/Group%2033939%402x.png"
              alt=""
            />
            <div class="right-title1">·Sensory Data</div>
            <div class="right-text6">
             <p>· Located on the far left column, displayed through visualized data charts. The chart contains importance icons,</p>
             <p> attribute names and data, numerical graphs, name tags, and tag values.</p>
             <p>· The blue bar is the sensory value chart of the product. If a comparison product is selected, the sensory data of </p>
             <p>the comparison product will also be displayed.</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Groupen33972%402x.png"
              alt=""
            />
            <div class="right-title1">·AI Prediction</div>
            <div class="right-text7">AI Prediction is located in the middle column and its results are predicted based on sensory data. It contains 
              <p>Overall Liking (OAL), Overall Attributes, and Feature Attributes.</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Group%2033965%402x.png"
             
              alt=""
            />
            <div class="right-title1">（1）Overall preference</div>
            <div class="right-text7">OAL of the product is displayed in chart form, and the chart type can be switched.</div>
            <img
              class="loding-img8"
               src="https://newstore.vynior.com/Groupen33969%402x.png"
              alt=""
            />
            <div class="right-title1">（2）Overall Attributes and Feature Attributes</div>
            <div class="right-text7">· Each attribute contains the attribute name, predicted value, and prediction accuracy of the attributes.</div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Groupen34062%402x.png"
              alt=""
            />
            <div class="right-title1">·Choose Products for Comparison</div>
            <div class="right-text10">
            <p>· Located in the rightmost column, it includes "Optimal Solution" and "Historical Products". </p>
            <p>Users can choose from these to compare with the analyzed product.</p>
            <p>· The 3 optimal solutions are generated by the algorithm based on the model.</p>
            <p> · The historical products refer to products in the same category that have already been analyzed in previous </p>
            <p>projects.</p>
              <span style="color: #eb5e12">Note: </span>The upper limit for selecting comparison products is 4.
            </div>
             <div class="right-title1">·Comparative Analysis</div>
              <div class="right-text7">
                Click " Comparative Analysis" button to jump to the comparative analysis page while retaining the selected project
                <p>and product.</p>
            </div>
              <img
              class="loding-img"
              src="https://newstore.vynior.com/Groupen33924%402x.png"
              alt=""
            />
            <img
              class="loding-img"
             src="https://newstore.vynior.com/Groupen133924%402x.png"
              alt=""
            />
          </div>

          <!-- ----------左边盒子   对比分析 -->
          <div
            class="right"
            v-if="ifShow[2]"
          >
            <div class="right-top">
              <div class="right-top-img"></div>
              <div class="right-top-text">·Comparative Analysis (Steps)</div>
            </div>
            <div class="right-text8">
              <p>On the comparative page for sensory attribute analysis of multiple products, you can freely choose products from</p>
              <p> different projects within the same category for visual data display and observation. (Note: Up to 10 products can </p>
              <p>be selected for comparison.)</p>
            </div>
            <img
              class="loding-img1"
                src="https://newstore.vynior.com/Groupen33995%402x.png"
              alt=""
            />
             <div class="right-title1">·Sensory Attribute Data</div>
            <div class="right-text13">
              <p>· Consists of importance, attribute name, attribute value, product name, and corresponding chart.</p>
               <p> · The attribute name will change accordingly based on the category. </p>
                <p>· The attribute value range is 0-15, with a precision of one decimal place.</p>
                 <p> · The blue color bar represents the attribute value graph.</p>

            </div>
           <img
              class="loding-img"
              src="https://newstore.vynior.com/Groupen33979%402x.png"
            
              alt=""
            />
            <div class="right-title1">·Comparative Analysis Results</div>
            <div class="right-text11">
             <p>· Consists of compared product name and positive/negative difference chart. </p>
             <p> · In the chart, the green color bar represents negative values, while the red color bar represents positive values. </p>
             <p>  The difference in sensory attributes between the selected product and chosen product will be displayed below the </p>
             <p>color bar, with precision to one decimal place. </p>
             <p> · If you want to view the project number and full product name of the compared product, simply hover your mouse </p>
             <p>over the product name and you can see the corresponding information in a pop-up window.</p>
            </div>
            <img
              class="loding-img"
               src="https://newstore.vynior.com/Groupen33980%402x.png"
              alt=""
            />
            <div class="right-title1">·Single Product Analysis</div>
            <div class="right-text7">Click on the "Single Product Analysis" button, and you will be directed to the Single Product Analysis page while
              <p>retaining the selected project and product.</p>
            </div>
            <img
              class="loding-img2"
             src="https://newstore.vynior.com/Groupen33974%402x.png"
              alt=""
            />
          </div>

          <!-- -------左边盒子  历史数据比较 --- -->
          <div
            class="right"
            v-if="ifShow[3]"
          >
            <div class="right-top">
              <div class="right-top-img"></div>
              <div class="right-top-text">Historical Data Comparison</div>
            </div>

            <div class="right-text5">This section can compare sensory values and predicted consumer values of multiple products in the same 
              <p>category from all historical projects and display them visually.</p>
            </div>
             <div class="right-title1"> ·Steps:</div>
            <div class="right-text13">
            <p>① First, select "Category" to confirm the project category.</p>
             <p>② Choose one or more products for comparison.</p>
              <p><span style="color: #eb5e12">Note: </span>A maximum of 30 products can be selected for comparison in the histogram, and a maximum of 8 products</p>
              <p> can be selected for comparison in the radar chart.</p>
            </div>
            <img
              class="loding-img"
             src="https://newstore.vynior.com/Groupen33943%402x.png"
              alt=""
            />
            <div class="right-title1">Sensory Results & Predicted Results</div>
            <div class="right-text8">
             After selecting the products for comparison, the page will display the histogram of sensory attributes by default. 
             <p>To view the result of predicted results, please click on "Start" on the "Predicted Results" page to conduct the </p>
             <p> analysis.</p>
            </div>
            <img
              class="loding-img"
               src="https://newstore.vynior.com/Groupen33947%402x.png"
              alt=""
            />
            <div class="right-text9">Both sensory results and predicted results comparison can be displayed in the form of either histogram or radar
              <p>chart.</p>
            </div>
            <div class="right-title2">（1）Histogram</div>
            <div class="right-text13">
              <p> The histogram includes two sections: the chart and the data list. </p>
               <p> · The chart displays the project number, product name, title (showing the selected sensory/consumer attribute</p>
                <p>name), and the scale values (sensory attribute: 0-15; consumer attribute: 0-5). </p>
                 <p>· The data list shows the names of sensory/consumer attributes and their corresponding data.</p>
                  <p><span style="color: #eb5e12">Note: </span>Sensory data is rounded to one decimal place, consumer prediction data is rounded to two decimal places.</p>
            </div>
            <img
              class="loding-img3"
              src="https://newstore.vynior.com/Groupen33987%402x.png"
              alt=""
            />
            <div class="right-title2">·Steps：</div>
            <div class="right-text6">
              Mouse over the data list to scroll up and down for preview (when selecting more than 15 products, you can swipe 
              <p>left and right to view). Click on the sensory attribute in the data list to view.</p>
             <p><span style="color: #eb5e12">Note: </span>Click "Start" for "Predicted Results". The data chart will only be displayed after algorithm analysis. </p>
             <p>Otherwise, the page will display no data.</p>
            </div>
            <img
              class="loding-img"
             src="https://newstore.vynior.com/Groupen33988%402x.png"
              alt=""
            />
            <div class="right-title1">·How to quickly view the complete project number, product name and attribute name?</div>
            <div class="right-text7">Hover your mouse over the project number and product name or attribute name for more than 1 second, and a
              <p>detailed name tag will be displayed.</p>
            </div>
            <img
              class="loding-img"
               src="https://newstore.vynior.com/%E6%88%AA%E5%B1%8F2023-03-01%E4%B8%8B%E5%8D%885.49%201%402x.png"
              alt=""
            />
            <div class="right-title1">·Percentile Line</div>
            <div class="right-text5">
              This function is only available in the histogram of predicted results. Click the "Percentile Line" button to control 
              <p>the display and hiding of the percentile line.</p>
            </div>
            <img
              class="loding-img"
              
              src="https://newstore.vynior.com/%E6%88%AA%E5%B1%8F2023-03-01%E4%B8%8B%E5%8D%885.49%202%402x.png"
              alt=""
            />
            <div class="right-title1">（2）Radar Chart</div>
            <div class="right-text3">
              <p>· Click "Radar Chart" to switch to the attribute comparison page in radar chart style, with two sections including </p>
              <p>radar chart and data list.</p>
              <p> · The left data list mainly displays the names of sensory/consumer attributes (you can select the attributes you</p>
              <p>want to view), project number & product name, and corresponding data. </p>
              <p>· The right radar chart mainly displays attribute names & radial axes, scale numbers (can slide to show or hide </p>
              <p>scale numbers), radar chains, data points (when hovering over a data point, the corresponding product name & </p>
              <p>attribute data will be displayed) and legends.</p>
            </div>
            <img
              class="loding-img3"
              src="https://newstore.vynior.com/Groupen33990%402x.png"
              alt=""
            />
             <div class="right-title1">·Steps：</div>
            <div class="right-text3">
             <p>· By default, the attribute list displays 10 attributes. You can click "Choose" to expand the attribute drop-down</p>
             <p>menu and scroll up and down to preview the attributes.</p>
             <p>· After selecting the attributes, clicking anywhere outside the drop-down menu will automatically collapse it.</p>
             <p> · Choose at least 3 and at most 40 attributes. When the selected attributes are less than 3, it will automatically </p>
             <p>revert to default 10 attributes.</p>
              （<span style="color: #eb5e12">Note: </span>The predicted results will only display data charts after clicking "Start" and being analyzed by the algorithm;
              <p>otherwise, the page will show no data.</p>
            </div>
            <img
              class="loding-img"
               src="https://newstore.vynior.com/Groupen33948%402x.png"
            
              alt=""
            />
            <div class="right-title1">·Reset</div>
            <div class="right-text7">Click "Reset" to restore the selected attributes to the default 10.</div>
            <img
              class="loding-img"
             src="https://newstore.vynior.com/Groupen33949%402x.png"
              alt=""
            />
            <div class="right-title1">·How to hide the data list?</div>
            <div class="right-text7">Click the left arrow to collapse the data list, and only display the radar chart. Click "Expand Data List" to restore both the data list and the radar chart.</div>
            <img
              class="loding-img"
             src="https://newstore.vynior.com/Groupen33950%402x.png"
              alt=""
            />
            <div class="right-title1">·How to quickly view the data of a product on the radar chart?</div>
            <div class="right-text7">Hover the mouse over the data point on the radar link, and the product name and attribute value corresponding to 
              <p>that radar link will be displayed.</p>
            </div>
            <img
              class="loding-img"
               src="https://newstore.vynior.com/%E6%88%AA%E5%B1%8F2023-03-01%E4%B8%8B%E5%8D%885.56%201%402x.png"
              alt=""
            />
          </div>

          <!-- 左边盒子  产品轮廓 -->
          <div
            class="right"
            v-if="ifShow[4]"
          >
            <div class="right-top">
              <div class="right-top-img"></div>
              <div class="right-top-text">Category Analysis</div>
            </div>
            <div class="right-text9">
             <p>This section displays interactive charts generated based on the How-To Sensory Database, which can be used for </p>
             <p>further analysis of product features.</p>
            </div>
             <div class="right-title1">·Sensory Profile</div>
            <div class="right-text6">
              <p>Sensory Profile: This graph shows the range of scores for each sensory attributes in the current database, where </p>
              <p> MAX represents the maximum values and MIN represents the minimum values. The distribution of sensory </p>
              <p>characteristics of the selected products can be obtained by this graph.</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/%E6%88%AA%E5%B1%8F2023-03-01%E4%B8%8B%E5%8D%882.52%202%402x.png"
             
              alt=""
            />
             <div class="right-title1">·Steps:</div>
            <div
              class="right-text13"
            >① First, select "Category" to confirm the category of the project.
            <p>② Then select "Products" and choose one or more products from the drop-down menu (<span style="color: #eb5e12">Note: </span>up to 10 products</p>
            <p>can be selected). </p>
            <p> ③ Click "Analyze" to start algorithm analysis. Wait for the analysis to complete, and the line chart of the selected</p>
          <p>product(s) data will be displayed.</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Groupen1133932%402x.png"
              alt=""
            />
            <div class="right-title1">·Range:</div>
            <div
              class="right-text4"
            >The sliding button can control the hiding and displaying of the high and low peak value range.
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Groupen33933%402x.png"
              alt=""
            />

          </div>

          <!-- 左边盒子  产品分类-->
          <div
            class="right"
            v-if="ifShow[5]"
          >
            <div class="right-top">
              <div class="right-top-img"></div>
              <div class="right-top-text">Cluster Analysis</div>
            </div>
            <div class="right-text8">
            <p>According to different SPF values and formulation system, products are labelled with different colors. SPF value</p>
            <p> includes SPF<30, 30≤SPF<50, SPF≥50 and None. Formulation system includes physical, chemical and hybrid. </p>
            <p><span style="color: #eb5e12">Note: </span>SSXXX(e.g. SS888) represents the product code in the database.</p>
            </div>
             <div class="right-title1">·Steps:</div>
            <div class="right-text13">
            <p>① Firstly, select "Category" to confirm the category of the project. The initial clustering diagram will be displayed</p>
            <p>with all products from the database (product code is displayed in black by default). </p>
            <p>② Then select "Products" and choose one or more products from the drop-down menu.</p>
            <p>③ Click "Analyze" to start algorithm analysis. Wait for the analysis to complete. The name(s) of the selected</p>
            <p>product(s) will appear in the diagram and be highlighted in red.</p>
            </div>
            <img
              class="loding-img"
               src="https://newstore.vynior.com/Groupen33936%402x.png"
              alt=""
            />
            <div class="right-title1">·Label</div>
            <div class="right-text9">
           This button is to control the display and hide of product code in the clustering diagram of the database.
            </div>
            <img
              class="loding-img"
               src="https://newstore.vynior.com/Groupen34063%402x.png"
              alt=""
            />
            <div class="right-title1">·Classification options -- SPF Value & Formulation System</div>
            <div class="right-text6">
            <p>· Click on the round button of "SPF Value" and the SPF value classification legend will appear, allowing users to</p>
            <p>quickly understand the classification status of products with corresponding colors in the Clustering Diagram. </p>
            <p>· For example: Products with SPF＜30 are marked in yellow, and the product code for SPF＜30 products in the</p>
            <p>Clustering Diagram on the right will also appear in yellow; The same applies to formulation system.</p>
            <p>· Click the round button again to cancel the selection.</p>
            </div>
            <img
              class="loding-img"
                src="https://newstore.vynior.com/Groupen33983%402x.png"
              
              alt=""
            />
          </div>

          <!-- 左边盒子  特征分析 -->
          <div
            class="right"
            v-if="ifShow[6]"
          >
            <div class="right-top">
              <div class="right-top-img"></div>
              <div class="right-top-text">Feature Analysis</div>
            </div>
            <div class="right-text2">
            <p>This graph shows the location of the selected product(s) in the Sensory Map.</p>
             <p><span style="color: #eb5e12">Legend description:</span>The blue star in the figure represents the product selected by the user for analysis, the</p>
             <p>triangle represents the products in the database, and the pink circle represents the sensory attributes of this</p>
             <p>category.</p>
            </div>
            <img
              class="loding-img"

                src="https://newstore.vynior.com/%E6%88%AA%E5%B1%8F2023-03-01%E4%B8%8B%E5%8D%883.43%201%402x.png"
              alt=""
            />
            <div class="right-text10">
              <span><span style="font-weight: 500">·Steps: </span></span>
            <p>① First, select "Category" to confirm the project category, and then select "Products". Choose one or more</p>
             <p>products from the drop-down menu, and click "Analyze" to start algorithm analysis. Wait for the analysis to</p>
              <p> complete, and the selected product name(s) and legends will be displayed on the sensory map. </p>
              <p>② The graph can be selected by factors, as well as two options: "Principal Component Analysis (PCA)" and </p>
              <p>"Factor Rotation Analysis".</p>
            </div>
            <img
              class="loding-img"
               src="https://newstore.vynior.com/Groupen33938%402x.png"
              
              alt=""
            />
            <div class="right-title1">·By Factor</div>
            <div class="right-text12">
              <p>Scroll up and down and choose the factors (a total of 15 combination selections, with F1&F2 as the default). The </p>
              <p>Sensory Map will change according to the selected factors.</p>
            </div>
            <img
              class="loding-img"
             src="https://newstore.vynior.com/Groupen33985%402x.png"
              alt=""
            />
            <div class="right-title1">PCA & Rotated</div>
            <div class="right-text3">
            <p> Both PCA and rotated analysis include three options: Clustering, SPF values, and Formulation System.</p>
            <p> · When selecting "None" option in "Clustering", the triangles (products in the database) displayed on the graph will</p>
            <p>be grayed out, indicating that there is no classification. At this time, users can click on the "None" option to</p>
            <p>expand the dropdown menu and select an option, such as selecting "6 Clusters". The products in the database will </p>
            <p> be divided into 6 clusters based on sensory data, and the triangles in the scatter plot will also be presented in 6</p>
            <p>colors.</p>
            <p>· When selecting "SPF values", the color of the triangles on the graph will change according to different SPF</p>
            <p>ranges. The "Formulation System" works similarly.</p>
            </div>
            <img
              class="loding-img"
             src="https://newstore.vynior.com/Groupen33984%402x.png"
              alt=""
            />
          </div>

          <!-- 左边盒子 重要度分析 -->
          <div
            class="right"
            v-if="ifShow[7]"
          >
            <div class="right-top">
              <div class="right-top-img"></div>
              <div class="right-top-text1">Importance Analysis</div>
            </div>

            <div class="right-text6">
             <p>This section provides information about the correlation between category attributes. There are three axis available: </p>
              <p>"Sensory Attributes", "Sensory/Consumer Attributes", and "Consumer Attributes".</p>
               <p><span style="color: #eb5e12">Note:</span>Analyzing "Sensory Attributes" and "Consumer Attributes" will display a heat map, while "Sensory/Consumer</p>
               <p>Attributes" will show both a heat map and a network graph.</p>
            </div>
            <img
              class="loding-img"
               src="https://newstore.vynior.com/Groupen33986%402x.png"
              alt=""
            />
            <div class="right-title1">·Heat Map</div>
            <div class="right-text6">
              <p>The darker the color of the tiny square, the greater the correlation between two attributes. The lighter the color,</p>
              <p>the lower the correlation between the attributes. Hover your mouse over the tiny square, you will see the specific </p>
              <p>value. A positive value indicates a positive correlation, while a negative value indicates a negative correlation.</p>
            </div>
           <div>
             <img
              class="loding-img7"
               src="https://newstore.vynior.com/2ac7dea2ddaa036e2ecc63c296857d5%201%402x.png"
              alt=""
            />
             <img
              class="loding-img7"
               src="https://newstore.vynior.com/8a3c33c9482a7eb77a6a90b41db1825%201%402x.png"
              alt=""
            />
           </div>
            <div class="right-title1">·Network</div>
            <div class="right-text6">
           <p>It shows 5 sensory attributes that have the greatest impact on this consumer attribute. You can select and see </p>
           <p>different consumer attributes. The left side is consumer attribute, and the right side is sensory attribute. The two</p>
           <p>are connected by a straight line. The color of the line represents positive/negative correlation. The value on the</p>
           <p>ine is correlation degree. The greater the absolute values, the stronger the correlation between the two.</p>
            </div>
            <img
              class="loding-img"
              
              src="https://newstore.vynior.com/%E6%88%AA%E5%B1%8F2023-03-01%E4%B8%8B%E5%8D%883.27%402x.png"
              alt=""
            />
              <div class="right-title1">·Steps：</div>
            <div class="right-text3">
           <p>① First, select "Category" to confirm the category.</p>
            <p>② Then, choose axis which includes "Sensory Attributes", "Sensory/Consumer Attributes", or "Consumer</p>
             <p>Attributes" from the drop-down menu.</p>
             <p>③ Click "Analyze" to start algorithm analysis. Wait for the analysis to complete and the pop-up window will </p>
             <p>isappear, displaying a heat map of the selected axis.</p>
              <p><span style="color: #eb5e12">Note: </span>The analysis results of "Sensory/Consumer Attributes" will have additional options for selecting attributes </p>
              <p>and chart type.</p>
            </div>
            <img
              class="loding-img"
              src="https://newstore.vynior.com/Groupen33937%402x.png"
              alt=""
            />
            <div class="right-title1">·Select Attributes&Reset (Only for Sensory/Consumer Attributes)</div>
            <div class="right-text6">
              <p>The attribute selection defaults to "Overall liking". Click on the selection box and choose the attribute you want to</p>
              <p>view, and the network graph will change accordingly. (<span style="color: #eb5e12">Note:</span>Attribute selection can only be done when switching to </p>
              <p>Attribute selection can only be done when switching to the network graph.)</p>
            </div>
            <img
              class="loding-img"
                src="https://newstore.vynior.com/Groupen33946%402x.png"
              alt=""
            />
          </div>

          <!-- 左边盒子  全部视频 -->
          <div
            class="rigth-videos"
            v-if="ifShow[8]"
          >
            <div class="videos-title">{{ $t(`message.HelpCenters.Allvideos`) }}</div>
            <div class="videos-box">
              <div class="videos">
                <div
                  class="video"
                  @click="videoPlay(1)"
                  style="cursor: pointer"
                >
                  <video
                    ref="myVideo"
                    style="cursor: pointer"
                    class="img1-video"
                    @play="videoPlay(1)"
                    poster="https://newstore.vynior.com/Rectangle%201533%281%29.png"
                  >
                    <source
                      :src="videourl"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
                <div class="video-bottom">{{ $t(`message.Header.two`) }}</div>
              </div>
              <div class="videos">
                <div
                  class="video"
                  @click="videoPlay(2)"
                  style="cursor: pointer"
                >
                  <video
                    ref="myVideo"
                    class="img1-video"
                    @play="videoPlay(2)"
                    poster="https://newstore.vynior.com/Rectangle%201533.png"
                  >
                    <!--   controls  控件是否展示 -->
                    <source
                      :src="videourl"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
                <div class="video-bottom">{{ $t(`message.Header.single`) }}</div>
              </div>
              <div class="videos">
                <div
                  class="video"
                  @click="videoPlay(3)"
                  style="cursor: pointer"
                >
                  <video
                    ref="myVideo"
                    class="img1-video"
                    @play="videoPlay(3)"
                    poster="https://newstore.vynior.com/Rectangle%201708.png"
                  >
                    <!--   controls  控件是否展示 -->
                    <source
                      :src="videourl"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
                <div class="video-bottom">{{ $t(`message.Header.compare`) }}</div>
              </div>
              <div class="videos">
                <div
                  class="video"
                  @click="videoPlay(4)"
                  style="cursor: pointer"
                >
                  <video
                    ref="myVideo"
                    class="img1-video"
                    @play="videoPlay(4)"
                    poster="https://newstore.vynior.com/Group%2034069.png"
                  >
                    <source
                      :src="videourl"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
                <div class="video-bottom">{{ $t(`message.Header.profile`) }}</div>
              </div>
              <div class="videos">
                <div
                  class="video"
                  @click="videoPlay(5)"
                  style="cursor: pointer"
                >
                  <!-- <img
                  class="img1-video"
                  src=""
                  alt=""
                > -->

                  <video
                    ref="myVideo"
                    class="img1-video"
                    @play="videoPlay(5)"
                    poster="https://newstore.vynior.com/Rectangle%201533%282%29.png"
                  >
                    <source
                      :src="videourl"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
                <div class="video-bottom">{{ $t(`message.Header.classification`) }}</div>
              </div>
              <div class="videos">
                <div
                  class="video"
                  @click="videoPlay(6)"
                  style="cursor: pointer"
                >
                  <!-- <img
                  class="img1-video"
                  src=""
                  alt=""
                > -->

                  <video
                    ref="myVideo"
                    class="img1-video"
                    @play="videoPlay(6)"
                    poster="https://newstore.vynior.com/Rectangle%201708%281%29.png"
                  >
                    <source
                      :src="videourl"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
                <div class="video-bottom">{{ $t(`message.Header.Feature`) }}</div>
              </div>
              <div class="videos">
                <div
                  class="video"
                  @click="videoPlay(8)"
                  style="cursor: pointer"
                >
                  <video
                    ref="myVideo"
                    class="img1-video"
                    @play="videoPlay(8)"
                    poster="https://newstore.vynior.com/Rectangle%201535%281%29.png"
                  >
                    <source
                      :src="videourl"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
                <div
                  class="video-bottom"
                  style="width: 180px"
                >{{ $t(`message.Header.significance`) }}</div>
              </div>
              <div class="videos">
                <div
                  class="video"
                  @click="videoPlay(8)"
                  style="cursor: pointer"
                >
                  <video
                    ref="myVideo"
                    class="img1-video"
                    @play="videoPlay(8)"
                    poster="https://newstore.vynior.com/Rectangle%201534%281%29.png"
                  >
                    <source
                      :src="videourl"
                      type="video/mp4"
                    />
                  </video>
                  <div class="img1-video-img"></div>
                  <img
                    class="img2-video"
                    v-if="videoPauseShow"
                    src="https://newstore.vynior.com/Vector.png"
                    alt=""
                  />
                </div>
                <div
                  class="video-bottom"
                  style="width: 180px"
                >{{ $t(`message.Header.five`) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频组件 -->
    <div
      class="videos-loding-box"
      v-if="videos"
    >
      <div class="videos-loding">
        <div class="videos-loding-top">
          <div class="videos-loding-title">{{ videotext }}</div>
          <div class="un-img">
            <img
              class="un-img-imgs"
              src="../assets/image/close.png"
              @click="unvideos"
              alt=""
            />
          </div>
        </div>
        <div class="videos-loding-main">
          <video
            controls
            autoplay
          >
            <!--   controls  控件是否展示 -->
            <source
              :src="videourl"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入
import { HttpClient } from '@/utils/http';
import { message } from '@/utils/resetMessage';
// 富文本引用
// 引入样式和quillEditor
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
import { isRef } from 'vue';
export default {
  name: 'HelpCenters',
  components: {
    quillEditor,
  },
  data() {
    return {
      allshow: false,
      hideRequired: true, // 定义表单的红色按钮
      isUser: true,
      isButtonColor1: [true, false], // 按钮的样式
      isSuccess: false, // 是否显示成功页面
      isAwait: false, // 是否显示等待页面
      isReject: false, // 是否显示错误页面
      currentHelp3: true, //页面无变化按钮
      currentHelp2: false, //常见问题按钮
      ifshow: false, //我要反馈界面
      isQuestion: false, //操作指引是否显示
      isOperation: false, //常见问题是否显示
      commonissue: true, //展示帮助中心
      operationguide: false, //展示帮助中心
      // 富文本编辑器配置
      editorOption: {
        placeholder: this.$t(`message.HelpCenters.placeholder`),
        modules: {
          toolbar: [['image', 'video']],
        },
      },
      //  表单数据
      formLabelAlign: {
        name: '', //名字
        title: '', // 标题
        email: '', // 联系方式
        htmldata: '', // 超文本
      },
      // !  操作指引 模块数据
      ifShow: [true, false, false, false, false, false, false, false, false], ///按钮控制颜色
      videos: false, //视频页面弹出
      videoPauseShow: true, //视频上面的按钮控制
      videotext: '', //视频页面文字
      videourl: '', //视频页面url'
      isButtonColor: [true, false, false, false, false, false, false, false, false], //切换操作指引按钮
    };
  },
  props: {
    helpDialogRole: {
      default: true,
    },
    num:{
  default: 0,
    },
    signName:{
      default:null,
    }
  },
  methods: {
    // 点击左上角变换对应也页面
    getcurrentHelp2() {
      this.currentHelp2 = false;
      this.currentHelp3 = true;
      this.ifshow = false;
      this.isButtonColor1.forEach((item, i) => {
        if (this.isButtonColor1[i] == true) {
          this.isButtonColor1[i] = false;
        }
      });
      this.$set(this.isButtonColor1, 0, true);
      this.isSuccess = false;
      this.isUser = true;
      this.isReject = false;
      this.nullData();
    },
    getcurrentHelp3() {
      this.currentHelp2 = true;
      this.currentHelp3 = false;
      this.isButtonColor1.forEach((item, i) => {
        if (this.isButtonColor1[i] == true) {
          this.isButtonColor1[i] = false;
        }
      });
      this.$set(this.isButtonColor1, 1, true);
      this.ifshow = false;
      this.isSuccess = false;
      this.isUser = true;
      this.isReject = false;
      this.nullData();
    },
    // 点击反馈按钮控制页面的显示
    getifshow() {
      this.isButtonColor1.forEach((item, i) => {
        if (this.isButtonColor1[i] == true) {
          this.isButtonColor1[i] = false;
        }
      });
      this.ifshow = true;
      this.isReject = false;
      this.isSuccess = false;
      this.isUser = false;
      this.nullData();
    },
    // 表单验证
    async handleLogin() {
      if (this.formLabelAlign.email == '' || this.formLabelAlign.email == null) {
        message.error('带*号的内容为必填项，请填写完整');
        return;
      }
      if (this.formLabelAlign.email) {
        const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
        if (!mailReg.test(this.formLabelAlign.email)) {
          message.error('邮箱账号格式错误，请重新填写');
          return;
        }
      }
      const { name, title, email, htmldata } = this.formLabelAlign;
      const data = `<h1>客户名称：${name}</h1><h1>公司名称：${title}</h1><h3>联系方式：${email}</h3><$>问题描述：${htmldata}</h3>`;

      await HttpClient.post('/subuser/emailfeedback', { html: data });
      setTimeout(() => {
        try {
          this.isAwait = false;
          console.log(data);
          console.log('成功');
          this.ifshow = false;
          this.isReject = false;
          this.isSuccess = true;
          this.currentHelp2 = false;
          this.currentHelp1 = false;
          this.currentHelp3 = false;

          this.allshow = false;
        } catch (error) {
          console.log('失败');
          this.isSuccess = false;
          this.isReject = true;
          this.ifshow = false;
        }
      }, 1000);
      this.isAwait = true;
      this.ifshow = false;
    },
    // 成功页面点击退出反馈按钮
    successOut() {
      this.isSuccess = false;
      this.ifshow = false;
      this.isUser = true;
      this.currentHelp2 = true;
      this.nullData();
    },
    // 成功页面点击继续反馈按钮
    successKeep() {
      this.isSuccess = false;
      this.isUser = false;
      this.ifshow = true;
      this.nullData();
    },
    // 失败页面点击退出反馈
    rejectOut() {
      this.currentHelp = 2;
      this.isReject = false;
      this.ifshow = false;
      this.isUser = true;
      this.isSuccess = false;
      this.currentHelp2 = true;
      this.nullData();
    },
    // 失败重新发送的请求
    rejectKeep() {
      this.handleLogin();
      console.log('在一次发送请求');
    },
    // 页面数据全部清除
    nullData() {
      this.formLabelAlign.title = null;
      this.formLabelAlign.email = null;
      this.formLabelAlign.htmldata = null;
      this.formLabelAlign.name = null;
    },

    // 邮箱验证
    emailInfo() {
      if (this.formLabelAlign.email) {
        const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
        if (!mailReg.test(this.formLabelAlign.email)) {
          this.$message({
            showClose: true,
            message: '邮箱账号格式错误，请重新填写',
            type: 'error',
            customClass: 'zZindex',
          });
        }
      }
    },
    // 退出弹窗
    un() {
      console.log('子组件传值');
      this.$emit('unloding1', false);
    },
    // 控制操作指引页面是否显示
    isShowQuestion() {
      this.isQuestion = true;
      this.isOperation = false;
      this.commonissue = true;
      this.operationguide = false;
    },
    // 控制创建问题是否显示
    isShowOperation() {
      this.isQuestion = false;
      this.isOperation = true;
      this.commonissue = false;
      this.operationguide = true;
    },
    // !!!!!!!!!对比分析方法
    link1() {
      // 切换相应页面
      this.ifShow.forEach((item, i) => {
        if (this.ifShow[i] == true) {
          this.ifShow[i] = false;
        }
      });
      this.$set(this.ifShow, 0, true);
      // 按钮 样式
      this.isButtonColor.forEach((item, i) => {
        if (this.isButtonColor[i] == true) {
          this.isButtonColor[i] = false;
        }
      });
      this.$set(this.isButtonColor, 0, true);
    },
    link2() {
      // 切换相应页面
      this.ifShow.forEach((item, i) => {
        if (this.ifShow[i] == true) {
          this.ifShow[i] = false;
        }
      });
      this.$set(this.ifShow, 1, true);
      // 按钮 样式
      this.isButtonColor.forEach((item, i) => {
        if (this.isButtonColor[i] == true) {
          this.isButtonColor[i] = false;
        }
      });
      this.$set(this.isButtonColor, 1, true);
    },
    link3() {
      // 切换相应页面
      this.ifShow.forEach((item, i) => {
        if (this.ifShow[i] == true) {
          this.ifShow[i] = false;
        }
      });
      this.$set(this.ifShow, 2, true);
      // 按钮 样式
      this.isButtonColor.forEach((item, i) => {
        if (this.isButtonColor[i] == true) {
          this.isButtonColor[i] = false;
        }
      });
      this.$set(this.isButtonColor, 2, true);
    },
    link4() {
      // 切换相应页面
      this.ifShow.forEach((item, i) => {
        if (this.ifShow[i] == true) {
          this.ifShow[i] = false;
        }
      });
      this.$set(this.ifShow, 3, true);
      // 按钮 样式
      this.isButtonColor.forEach((item, i) => {
        if (this.isButtonColor[i] == true) {
          this.isButtonColor[i] = false;
        }
      });
      this.$set(this.isButtonColor, 3, true);
    },
    link5() {
      // 切换相应页面
      this.ifShow.forEach((item, i) => {
        if (this.ifShow[i] == true) {
          this.ifShow[i] = false;
        }
      });
      this.$set(this.ifShow, 4, true);
      // 按钮 样式
      this.isButtonColor.forEach((item, i) => {
        if (this.isButtonColor[i] == true) {
          this.isButtonColor[i] = false;
        }
      });
      this.$set(this.isButtonColor, 4, true);
    },
    link6() {
      // 切换相应页面
      this.ifShow.forEach((item, i) => {
        if (this.ifShow[i] == true) {
          this.ifShow[i] = false;
        }
      });
      this.$set(this.ifShow, 5, true);
      // 按钮 样式
      this.isButtonColor.forEach((item, i) => {
        if (this.isButtonColor[i] == true) {
          this.isButtonColor[i] = false;
        }
      });
      this.$set(this.isButtonColor, 5, true);
    },
    link7() {
      // 切换相应页面
      this.ifShow.forEach((item, i) => {
        if (this.ifShow[i] == true) {
          this.ifShow[i] = false;
        }
      });
      this.$set(this.ifShow, 6, true);
      // 按钮 样式
      this.isButtonColor.forEach((item, i) => {
        if (this.isButtonColor[i] == true) {
          this.isButtonColor[i] = false;
        }
      });
      this.$set(this.isButtonColor, 6, true);
    },
    link8() {
      // 切换相应页面
      this.ifShow.forEach((item, i) => {
        if (this.ifShow[i] == true) {
          this.ifShow[i] = false;
        }
      });
      this.$set(this.ifShow, 7, true);
      // 按钮 样式
      this.isButtonColor.forEach((item, i) => {
        if (this.isButtonColor[i] == true) {
          this.isButtonColor[i] = false;
        }
      });
      this.$set(this.isButtonColor, 7, true);
    },
    link9() {
      // 切换相应页面
      this.ifShow.forEach((item, i) => {
        if (this.ifShow[i] == true) {
          this.ifShow[i] = false;
        }
      });
      this.$set(this.ifShow, 8, true);
      // 按钮 样式
      this.isButtonColor.forEach((item, i) => {
        if (this.isButtonColor[i] == true) {
          this.isButtonColor[i] = false;
        }
      });
      this.$set(this.isButtonColor, 8, true);
    },
    // 关闭视频页面
    unvideos() {
      this.videos = false;
      this.videoPauseShow = true;
    },
    // 点击播放
    videoPlay(e) {
      // 根据点击事件传过来的值，判断字或者url是多少
      switch (e) {
        case 1:
          this.videotext =this.$t(`message.Header.two`)  // 当表达式的结果等于 value1 时，则执行该代码
          this.videourl = 'https://store.vynior.com/%E9%87%8D%E8%A6%81%E5%BA%A6%E5%88%86%E6%9E%90.mp4'
          break;
        case 2:
          this.videotext = this.$t(`message.Header.single`) 
          this.videourl = 'https://store.vynior.com/%E5%8D%95%E5%93%81%E5%88%86%E6%9E%90.mp4';
          break;
        case 3:
          this.videotext = this.$t(`message.Header.compare`) 
          this.videourl = 'https://store.vynior.com/%E5%AF%B9%E6%AF%94%E5%88%86%E6%9E%90.mp4';
          break;
        case 4:
          this.videotext = this.$t(`message.Header.profile`) 
          this.videourl = 'https://store.vynior.com/%E4%BA%A7%E5%93%81%E8%BD%AE%E5%BB%93.mp4';
          break;
        case 5:
          this.videotext = this.$t(`message.Header.classification`)
          this.videourl = 'https://store.vynior.com/%E4%BA%A7%E5%93%81%E5%88%86%E7%B1%BB.mp4';
          break;
        case 6:
          this.videotext = this.$t(`message.Header.Feature`)
          this.videourl = 'https://store.vynior.com/%E7%89%B9%E5%BE%81%E5%88%86%E6%9E%90.mp4';
          break;
        case 7:
          this.videotext = this.$t(`message.Header.significance`)
          this.videourl = 'https://store.vynior.com/%E9%87%8D%E8%A6%81%E5%BA%A6%E5%88%86%E6%9E%90.mp4';
          break;
        case 8:
          this.videotext =this.$t(`message.Header.five`)
          this.videourl = 'https://store.vynior.com/%E5%8E%86%E5%8F%B2%E6%95%B0%E6%8D%AE%E6%AF%94%E8%BE%83.mp4';
          break;
        case 9:
          this.videotext = this.$t(`message.HelpCenters.Operatevideo`)
          this.videourl = 'https://store.vynior.com/%E6%96%B0%E5%BB%BA%E9%A1%B9%E7%9B%AE.mp4';
          break;
      }
      //点击盒子让播放按钮隐藏
      this.videoPauseShow = false;
      //触发video的播放事件
      // this.$refs.myVideo.play();  先不播放
      this.videos = true;
    },
  },
  mounted(){
console.log(this.signName);
        if(this.signName == "ItemAnalysis"){
              this.getcurrentHelp3()
              console.log("执行了");
        }
  },
watch:{
   "formLabelAlign.htmldata":{
      handler(New,Old){
          if(New&&New.includes("iframe")){ 
                let result = New.split('=')[4].split('"')[1];
                if(!result.includes("mp4")){
                      this.formLabelAlign.htmldata = Old
                      let iframe = document.querySelector("iframe")
                      iframe.remove();
                    // http://www.ht-fast.com/home_video/宣传片 全品类 字幕已修改1676610520688.mp4
                    //C:\Users\18102\Pictures\Videos\Captures\1.mp4
                }else{
                  let iframe = document.querySelector("iframe")
                // iframe.tagName = 'video'
                // iframe.width = "100%";
                // iframe.height = "100%";
                // iframe.controls = true;
                  console.log("iframe成功",iframe);
                }
          }
      }
    }
}

};
</script>
<style lang="less" scoped>
@import url("../style/HelpCenters.less");
</style>
